import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineChevronDown } from "react-icons/hi";
import { MediumText } from "../utils/layoutUtils";
import user_profile from "../assets/icons/icon_user_profile.svg";
import icon_folder from "../assets/icons/icon_folder.svg";
import icon_rsvp from "../assets/icons/icon_rsvp.svg";
import icon_logout from "../assets/icons/icon_logout.svg";
import { Popover } from "antd";
import myContext from "../state/myContext";
import { FiLogIn } from "react-icons/fi";
import user from "../assets/user.png";
import { FaRegUser } from "react-icons/fa";
import { openNotificationWithIcon } from "../App";
import { RiVipCrown2Line } from "react-icons/ri";

const HeaderComponent = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const menuRef1 = useRef(null);
  const {
    get_home_theme_list,
    category_data,
    setSelected_cat,
    activeTab,
    setActiveTab,
  } = useContext(myContext);
  const handleTabClick = (tab) => {
    navigate("/");
    setActiveTab(tab);
    setSelected_cat(category_data?.list[tab][0]);
    get_home_theme_list(tab, category_data?.list[tab][0]);
  };

  const toggleMenu1 = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef1.current && !menuRef1.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const hide1 = () => {
    setOpen1(false);
  };
  const handleOpenChange1 = (newOpen) => {
    setOpen1(newOpen);
  };

  const headerOptions = [
    "Wedding",
    // "Engagement", "Valentine's Week", "Birthday"
  ];
  return (
    <div className="relative inline-block w-full bg-white z-20" ref={menuRef}>
      <div className="relative w-full border-b-[1px] border-b-[#0000001A] h-auto md:h-auto xl:h-[80px] flex items-center justify-center flex-col md:pt-[20px] xl:pt-[0px] mt-3 md:mt-0">
        <div className="max-w-[90%] xl:max-w-[80%] flex w-full justify-between ">
          <img
            src={logo}
            className="w-[180px] object-contain cursor-pointer"
            onClick={() => navigate("/")}
          />
          <div className="flex xl:hidden ">
            <Popover
              content={<ProfileOption onClose={hide} />}
              title=""
              trigger="click"
              placement="topRight"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <div className="text-[#103935] text-[14px] font-inter px-2 group-hover:text-[#FF9500] flex gap-2 items-center">
                <div className="h-[30px] w-[30px] rounded-full overflow-hidden border-[#959059] border-[0px]">
                  <img src={user} className="h-full w-full object-cover" />
                </div>
                <HiOutlineChevronDown size={20} />
              </div>
            </Popover>
          </div>
          <div className="hidden xl:flex">
            <ul className="flex space-x-8 h-[80px] justify-center">
              {headerOptions.map((tab) => (
                <li
                  key={tab}
                  className={`group relative cursor-pointer items-center justify-center flex ${
                    activeTab === tab ? "text-[#FF9500]" : "text-[#103935]"
                  }`}
                  onClick={() => handleTabClick(tab)}
                >
                  <div className="text-[14px] font-inter px-2 group-hover:text-[#FF9500] whitespace-nowrap">
                    {tab}
                  </div>
                  <span
                    className={`absolute left-1/2 transform -translate-x-1/2 bottom-0 h-[2px] ${
                      activeTab === tab ? "w-full" : "w-0"
                    } bg-[#FF9500] transition-all duration-300 group-hover:w-full`}
                  ></span>
                </li>
              ))}
              {/* <li
                className="group relative cursor-pointer items-center justify-center flex"
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                // onClick={() => {
                //   toggleMenu1();
                //   // handleTabClick("More");
                // }}
              >
                <div className="text-[#103935] text-[14px] font-inter px-2 group-hover:text-[#FF9500] flex gap-2">
                  More
                  <HiOutlineChevronDown size={20} />
                </div>
                <span
                  className={`absolute left-1/2 transform -translate-x-1/2 bottom-0 h-[2px] ${
                    activeTab === "More" ? "w-full" : "w-0"
                  } bg-[#FF9500] transition-all duration-300 group-hover:w-full`}
                ></span>
              </li> */}

              <li className="group relative cursor-pointer items-center justify-center hidden xl:flex">
                <Popover
                  content={<ProfileOption onClose={hide1} />}
                  title=""
                  arrow={false}
                  trigger="click"
                  placement="topRight"
                  open={open1}
                  onOpenChange={handleOpenChange1}
                >
                  <div className="h-[70px] text-[#103935] text-[14px] font-inter px-2 group-hover:text-[#FF9500] flex gap-2 items-center">
                    <div className="h-[40px] w-[40px] rounded-full overflow-hidden bg-[#103935] border-[0px] justify-center items-center flex">
                      <FaRegUser size={20} color="#fff" />
                    </div>
                    <HiOutlineChevronDown size={20} />
                  </div>
                </Popover>
              </li>
            </ul>
          </div>
        </div>
        <div className="max-w-[90%] xl:max-w-[80%] flex xl:hidden w-full overflow-auto md:justify-center mt-2">
          <ul className="flex space-x-4 h-[50px] justify-center gap-3">
            {headerOptions.map((tab) => (
              <li
                key={tab}
                className={`group relative cursor-pointer items-center justify-center flex ${
                  activeTab === tab ? "text-[#FF9500]" : "text-[#103935]"
                }`}
                onClick={() => handleTabClick(tab)}
              >
                <div className="text-[14px] font-inter px-2 group-hover:text-[#FF9500] whitespace-nowrap">
                  {tab}
                </div>
                <span
                  className={`absolute left-1/2 transform -translate-x-1/2 bottom-0 h-[2px] ${
                    activeTab === tab ? "w-full" : "w-0"
                  } bg-[#FF9500] transition-all duration-300 group-hover:w-full`}
                ></span>
              </li>
            ))}
            {/* <li
              className="group relative cursor-pointer items-center justify-center flex"
              onClick={() => {
                toggleMenu1();
                // handleTabClick("More");
              }}
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
            >
              <div className="text-[#103935] text-[14px] font-inter px-2 group-hover:text-[#FF9500] flex gap-2">
                More
                <HiOutlineChevronDown size={20} />
              </div>
              <span
                className={`absolute left-1/2 transform -translate-x-1/2 bottom-0 h-[2px] ${
                  activeTab === "More" ? "w-full" : "w-0"
                } bg-[#FF9500] transition-all duration-300 group-hover:w-full`}
              ></span>
            </li> */}
            {/* <li
              className="group relative cursor-pointer items-center justify-center flex"
              // onClick={() => handleTabClick("Support")}
            >
              <div className="text-[#103935] text-[14px] font-inter px-2 group-hover:text-[#FF9500] flex gap-2">
                Support
                <CiHeadphones size={20} />
              </div>
              <span
                className={`absolute left-1/2 transform -translate-x-1/2 bottom-0 h-[2px] ${
                  activeTab === "Support" ? "w-full" : "w-0"
                } bg-[#FF9500] transition-all duration-300 group-hover:w-full`}
              ></span>
            </li> */}
          </ul>
        </div>
      </div>

      {isOpen && (
        <div
          className="absolute w-full h-auto bg-[#00000066] z-20"
          ref={menuRef1}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <div
            className="w-full bg-[#103935] h-auto flex items-center justify-center z-20 inset-0 py-4 md:py-8"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
          >
            <div
              className="max-w-[90%] lg:max-w-[80%] flex w-full flex-col gap-4 overflow-auto"
              ref={menuRef1}
            >
              <div className="flex gap-4 md:gap-8 lg:gap-10 ">
                {Object.keys(category_data.list).map((category) => (
                  <div key={category}>
                    <MediumText className={"mb-2"}>{category}</MediumText>
                    {category_data.list[category].length > 0 ? (
                      <div className="gap-2 flex flex-col">
                        {category_data.list[category].map((event, index) => (
                          <div
                            key={index}
                            className={`font-inter font-normal text-[#FF9500]  hover:text-[#fff] text-clamp-sm cursor-pointer `}
                          >
                            {event}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-[#888] font-inter text-clamp-sm">
                        No events available
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex w-full h-full " onClick={toggleMenu1}></div>
        </div>
      )}
    </div>
  );
};

const ProfileOption = ({ onClose }) => {
  const navigate = useNavigate();
  const { setShow_login_modal } = useContext(myContext);
  const onLogout = () => {
    localStorage.removeItem("token");
    openNotificationWithIcon("warning", "Log Out", "Logged Out Successfully");
    navigate("/");
  };
  return (
    <div className="p-2 gap-2 flex flex-col ">
      <div
        className="text-[#103935] font-inter  text-[16px] flex gap-2 cursor-pointer"
        role="menuitem"
        tabIndex="-1"
        id="menu-item-0"
        onClick={() => {
          navigate("profile");
          onClose();
        }}
      >
        <img src={user_profile} className="h-[20px] w-[20px] object-contain" />
        Profile
      </div>
      <div
        className="text-[#103935] font-inter  text-[16px] flex gap-2 cursor-pointer"
        role="menuitem"
        tabIndex="-1"
        id="menu-item-0"
        onClick={() => {
          navigate("subscription");
          onClose();
        }}
      >
        <RiVipCrown2Line size={22} />
        Subscription
      </div>
      <div
        className="text-[#103935] font-inter  text-[16px] flex gap-2 cursor-pointer"
        role="menuitem"
        tabIndex="-1"
        id="menu-item-0"
        onClick={() => {
          navigate("my-order");
          onClose();
        }}
      >
        <img src={icon_folder} className="h-[20px] w-[20px] object-contain" />
        My Order
      </div>
      {/* <div
        className="text-[#103935] font-inter  text-[16px] flex gap-2 cursor-pointer"
        role="menuitem"
        tabIndex="-1"
        id="menu-item-0"
        onClick={() => {
          navigate("/transactions");
          onClose();
        }}
      >
        <img src={icon_rsvp} className="h-[20px] w-[20px] object-contain" />
        Transactions
      </div> */}
      {/* <div
        className="text-[#103935] font-inter  text-[16px] flex gap-2 cursor-pointer"
        role="menuitem"
        tabIndex="-1"
        id="menu-item-0"
        onClick={() => {
          navigate("rsvp");
          onClose();
        }}
      >
        <img src={icon_rsvp} className="h-[20px] w-[20px] object-contain" />
        RSVP
      </div> */}
      <div
        className={`${
          localStorage.getItem("token") == null
            ? "text-[#103935]"
            : "text-[#f00]"
        } font-inter  text-[16px] flex gap-2 cursor-pointer items-center`}
        role="menuitem"
        tabIndex="-1"
        id="menu-item-0"
        onClick={() => {
          onClose();
          localStorage.getItem("token") == null
            ? setShow_login_modal(true)
            : onLogout();
        }}
      >
        {localStorage.getItem("token") == null ? (
          <FiLogIn size={20} color="#103935" />
        ) : (
          <img src={icon_logout} className="h-[20px] w-[20px] object-contain" />
        )}
        {localStorage.getItem("token") == null ? "Log In" : "Log Out"}
      </div>
    </div>
  );
};

export default HeaderComponent;
