import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const user = JSON.parse(localStorage.getItem("token"));

export const fetchCategories = createAsyncThunk("category/list", async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "list-categories",
    { maxBodyLength: Infinity }
  );
  return response.data;
});

export const fetchOrders = createAsyncThunk("orders/list", async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_API_URL + `get-orders`,
    headers: {
      Auth_ID_Token: user.IdToken,
      Access_Token: user.AccessToken,
    },
  };
  const response = await axios.request(config);
  return response.data;
});

const userSlice = createSlice({
  initialState: {
    banner_visible: true,
    save_the_date: false,

    category_list: [],
    category_loading: [],

    my_theme: {},
    theme_preview: {},
    theme_json: {},
    theme_audio: [],
    theme_pages: [],
    updated_pages: [],

    selected_theme: {},
    selected_json: [],
    selected_pages: [],

    edit_page: 0,
    event_page: 1,
    deleted_video: [],
    post_order: {},
    order_history: {},
    video_data: {},

    snap_shot: [],

    order_loading: false,
    order_list: [],
    order_error: "",

    user_data: {},
    user_transactions: [],
  },
  name: "user",

  reducers: {
    setSave_the_date(state, action) {
      state.save_the_date = action.payload;
    },
    setBanner_visible(state, action) {
      state.banner_visible = action.payload;
    },
    setMy_Theme(state, action) {
      const data = action.payload;
      state.my_theme = data.body;
      state.theme_preview = {
        preview_images: data?.body?.preview_images,
        preview_video: data?.body?.preview_video,
      };
      state.theme_json = data?.body?.theme_json;
      state.theme_pages = data?.body?.theme_json?.pages;
      state.theme_audio = data?.body?.theme_json?.audios;

      const pages_list = data?.body?.theme_json?.pages;
      const video = [];
      for (let index = 0; index < pages_list?.length; index++) {
        const element = {
          pageName: pages_list[index]?.pageName,
          deleted: false,
          snap: "",
        };
        video.push(element);
      }

      state.updated_pages = video;
    },

    setSelected_Theme(state, action) {
      const data = action.payload;
      state.selected_theme = data?.body;
      state.selected_pages = data?.body?.theme_json?.pages;
      state.selected_json = data?.body?.theme_json;
      const pages_list = data?.body?.theme_json?.pages;
      const video = [];
      for (let index = 0; index < pages_list?.length; index++) {
        const element = {
          pageName: pages_list[index]?.pageName,
          deleted: false,
        };
        video.push(element);
      }
      state.deleted_video = video;
    },

    setSelected_Pages(state, action) {
      state.selected_pages = action.payload;
    },

    setJson_Data(state, action) {
      state.selected_json = action.payload;
    },

    setEdit_Page(state, action) {
      state.edit_page = action.payload;
    },

    setEvent_Page(state, action) {
      state.event_page = action.payload;
    },

    setDeleted_video(state, action) {
      state.deleted_video = action.payload;
    },

    setPost_order(state, action) {
      state.post_order = action.payload;
    },

    setOrder_history(state, action) {
      state.order_history = action.payload;
    },

    setVideo_Data(state, action) {
      state.video_data = action.payload;
    },

    setSnap_shot(state, action) {
      state.snap_shot = action.payload;
    },
    setUser_Data(state, action) {
      state.user_data = action.payload;
    },
    setUser_transactions(state, action) {
      state.user_transactions = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.category_loading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.category_loading = false;
        const response = action.payload;
        if (response.status == 200) {
          state.category_loading = response.data?.body;
        } else {
          state.category_loading = [];
        }
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.category_loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchOrders.pending, (state) => {
        state.order_loading = true;
        state.order_error = "";
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.order_loading = false;
        const response = action.payload;
        if (response.statusCode == 200) {
          state.order_list = response?.body?.items;
        } else {
        }
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.order_loading = false;
        state.order_error = action.error.message;
      });
  },
});

export const {
  setSelected_Theme,
  setJson_Data,
  setSelected_Pages,
  setEdit_Page,
  setEvent_Page,
  setDeleted_video,
  setPost_order,
  setOrder_history,
  setVideo_Data,
  setSnap_shot,
  setBanner_visible,
  setMy_Theme,
  setSave_the_date,
  setUser_Data,
  setUser_transactions,
} = userSlice.actions;
export default userSlice.reducer;
