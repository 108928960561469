import React, { useContext, useEffect, useState } from "react";
import { Input, generateSecretHash } from "../../utils/layoutUtils";
import { CognitoIdentityServiceProvider, config } from "aws-sdk";
import myContext from "../../state/myContext";
import { Modal, message } from "antd";
import { IoMdClose } from "react-icons/io";
import { Oval } from "react-loader-spinner";
import { handleError, handleLoginError } from "../../utils/utils";
import CustomInput from "../../components/CustomInput";
import ForgetPassword from "./ForgetPassword";
import Signup from "./Signup";
import { openNotificationWithIcon } from "../../App";
import { get_user_profile } from "../../utils/apis";

config.update({ region: "ap-south-1" });
const cognito = new CognitoIdentityServiceProvider();

const Login = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { show_login_modal, setShow_login_modal } = useContext(myContext);
  const [for_pass, setFor_pass] = useState(false);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // Function to log in a user
  const loginUser = async () => {
    if (!form.email || !form.password) {
      openNotificationWithIcon(
        "info",
        "Alert",
        "Please enter username or password."
      );
      return;
    }

    setSubmitting(true);
    const secretHash = await generateSecretHash(form.email);
    const params = {
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      AuthParameters: {
        USERNAME: form.email,
        PASSWORD: form.password,
        SECRET_HASH: secretHash,
      },
    };

    try {
      const response = await cognito.initiateAuth(params).promise();
      openNotificationWithIcon("success", "Success", "Login successful!");
      localStorage.setItem(
        "token",
        JSON.stringify(response.AuthenticationResult)
      );
      const expirationTime =
        Date.now() + response.AuthenticationResult.ExpiresIn * 1000; // Current time + expiresIn in milliseconds
      localStorage.setItem("tokenExpiration", expirationTime);
      setShow_login_modal(false);
      setSubmitting(false);
      get_user_profile();
    } catch (error) {
      handleLoginError(error);
      setSubmitting(false);
    }
  };
  useEffect(() => {
    setIsSignUp(false);
    setFor_pass(false);
  }, [show_login_modal]);

  useEffect(() => {
    setForm({
      ...form,
      email: "",
      password: "",
    });
  }, [show_login_modal]);

  const handleCancel = () => {
    setShow_login_modal(false);
  };
  return (
    <>
      <Modal
        width={window.innerWidth < 400 ? "90%" : "490px"}
        styles={{
          content: {
            backgroundImage: "linear-gradient(to bottom, #033A37, #1E635F)",
            borderRadius: 20,
          },
          header: { backgroundColor: "transparent" },
        }}
        title={""}
        open={show_login_modal}
        footer={null}
        centered
        closeIcon={null}
      >
        <div className="flex flex-col py-1 px-3 md:py-4 md:px-6">
          <div className="flex  items-center">
            <div className="font-baskerville text-clamp-popup-title text-[#fff] flex-1">
              {isSignUp
                ? "Create an Account"
                : for_pass
                ? "Forgot Password"
                : "Log In"}
            </div>
            <IoMdClose
              size={30}
              color="#fff"
              onClick={handleCancel}
              className="cursor-pointer"
            />
          </div>
          {isSignUp && <Signup setIsSignUp={setIsSignUp} />}

          {for_pass && (
            <ForgetPassword
              setFor_pass={setFor_pass}
              setIsSignUp={setIsSignUp}
            />
          )}
          {!isSignUp && !for_pass && (
            <>
              <CustomInput
                className={"mt-8"}
                placeholder="Enter here"
                label={"Email ID*"}
                value={form.email}
                name={"email"}
                onChange={handleChange}
                type="email"
              />
              <CustomInput
                isPassword
                className={"mt-4"}
                placeholder="Enter here"
                label={"Password*"}
                type="password"
                value={form.password}
                onChange={handleChange}
                name={"password"}
              />

              <div className="flex  my-4 justify-end">
                <div
                  className="text-[#FCD36D] font-inter font-medium text-clamp-sm cursor-pointer flex "
                  onClick={() => setFor_pass(true)}
                >
                  Forgot password?
                </div>
              </div>
              <button
                onClick={loginUser}
                disabled={isSubmitting}
                className={`flex justify-center border-[1px] border-[#CD8E42] h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-[15px] text-clamp-base18 text-[#071524] font-inter font-medium bg-gradient-to-r from-[#FFF090] to-[#FABF55]`}
              >
                {isSubmitting ? (
                  <>
                    <Oval
                      visible={true}
                      height="30"
                      width="30"
                      color="#FF9500"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      secondaryColor="#FF950055"
                      wrapperClass=""
                      strokeWidth={6}
                    />
                  </>
                ) : (
                  "Submit"
                )}
              </button>
              <p className="text-center font-inter text-[#FFFFFF] text-clamp-sm mt-4 md:mt-8">
                <span
                  className="text-[#FCD36D] cursor-pointer font-medium"
                  onClick={() => {
                    setForm({
                      email: "",
                      password: "",
                    });
                    setIsSignUp(true);
                  }}
                >
                  Sign Up
                </span>{" "}
                if they don't have an account
              </p>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Login;
