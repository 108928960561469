import React, { useContext, useEffect, useRef, useState } from "react";
import PageFrame from "../components/PageFrame";
import moment from "moment";
import { Divider, Modal } from "antd";
import myContext from "../state/myContext";
import { isTokenExpired } from "../utils/utils";
import { Button, PageLoader } from "../utils/layoutUtils";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import { FiLogIn } from "react-icons/fi";
import { openNotificationWithIcon } from "../App";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Refresh } from "../assets/refresh.svg";
import { ReactComponent as Success } from "../assets/success.svg";
import CustomModal from "../components/CustomModal";
import loader_gif from "../assets/loader1.gif";
import calendar from "../assets/calendar.png";
import download from "../assets/download.png";
import { FadeLoader, HashLoader, PulseLoader } from "react-spinners";
import { HiOutlineDownload } from "react-icons/hi";
import { Oval, RotatingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { get_user_transaction } from "../utils/apis";

const useResponsiveWidth = () => {
  const [width, setWidth] = useState(
    window.innerWidth < 500
      ? window.innerWidth / 4.43
      : window.innerWidth / 7.15
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(
        window.innerWidth < 500
          ? window.innerWidth / 4.43
          : window.innerWidth / 7.15
      );
    };

    window.addEventListener("resize", handleResize);
    // Call handler immediately to set initial width
    handleResize();

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
};
const Transactions = () => {
  const isMobileView = window.innerWidth < 768; // Change breakpoint if needed
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("token"));
  const dynamicWidth = useResponsiveWidth();
  const videoRef = useRef(null);
  const [open_modal, setOpen_modal] = useState(false);
  const { setShow_login_modal } = useContext(myContext);
  const [isPlaying, setPlaying] = useState(false);
  const [video_url, setVideo_url] = useState({});
  const { user_transactions } = useSelector((state) => state.user);
  const [order_loading, setOrder_loading] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) {
      setShow_login_modal(true);
      return;
    }
    get_user_transaction();
  }, []);

  return (
    <>
      <PageFrame current_page={"My Order"}>
        {user == null ? (
          <div className="justify-center flex items-center my-4 flex-col gap-4">
            <p className="font-inter font-normal text-[#071524]">
              You are not logged in, Please log in
            </p>
            <button
              onClick={() => setShow_login_modal(true)}
              className={`flex gap-2 bg-transparent h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-clamp-x-slider-button text-clamp-base18 text-[#071524] font-inter font-medium border border-[#FF0000]`}
            >
              <FiLogIn size={20} color="#103935" />
              Log In
            </button>
          </div>
        ) : order_loading ? (
          <PageLoader />
        ) : user_transactions?.length == 0 && !order_loading ? (
          <div className="items-center flex flex-col gap-4 my-6">
            <p className="font-inter font-normal text-[#071524] text-center ">
              You haven't created any invitation yet please
              <br />
              Create your first invitation to get started!
            </p>
            <Button
              title={"Create Invitation"}
              onClick={() => navigate("/")}
              className={"px-11"}
            ></Button>
          </div>
        ) : (
          <div className="my-4 md:m-4 gap-4 flex flex-col">
            <div className="items-center flex flex-col gap-2">
              <button
                onClick={() => get_user_transaction()}
                className={`flex gap-2 bg-[#FFF7EA] h-clamp-slider-button rounded-full items-center px-6 text-clamp-base18 text-[#071524] font-inter font-medium border border-[#F5BD58]`}
              >
                <Refresh className="h-10  object-contain" />
                Refresh
              </button>
              <p className="font-inter font-medium text-[#C21E56] text-center">
                Click on refresh button to see updated status
              </p>
            </div>
            {isMobileView ? ( // Mobile Card Layout
              <div className="flex flex-col gap-4 p-4">
                {user_transactions?.map((item, index) => (
                  <div key={index} className={` shadow-lg bg-white `}>
                    <h2 className="text-lg font-medium text-[#071524] p-2">
                      {item.theme_name}
                    </h2>
                    <Divider
                      className="p-0 m-0"
                      style={{ height: "1px", backgroundColor: "#00000014" }}
                    />
                    <div className="flex items-center gap-2 p-2">
                      <img src={calendar} className="h-4 w-4 object-contain" />
                      <p className="text-[#071524] flex-1">
                        {moment(item.theme_gen_req_date).format(
                          "DD-MM-YYYY hh:mm a"
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="overflow-x-auto ">
                <table className="min-w-full bg-white border border-gray-200 font-inter font-normal">
                  <thead>
                    <tr className="w-full bg-gray-100 border-b border-gray-200">
                      <th className="py-2 px-2 text-left text-[#071524] ">#</th>
                      <th className="py-2 px-2 text-left text-[#071524] min-w-[170px]">
                        Theme Name
                      </th>
                      <th className="py-2 px-2 text-left text-[#071524] min-w-[190px]">
                        Date
                      </th>
                      <th className="py-2 px-2 text-left text-[#071524]">
                        Status
                      </th>
                      <th className="py-2 px-2 text-left text-[#071524]">
                        Download
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {user_transactions?.map((item, index) => (
                      <tr key={index} className="border-b border-gray-200">
                        <td className="py-2 px-2 text-[#071524]">
                          {index + 1}
                        </td>
                        <td className="py-2 px-2 text-[#071524]">
                          {item.theme_name}
                        </td>
                        <td className="py-2 px-2 text-[#071524]">
                          {moment(item.theme_gen_req_date).format(
                            "DD-MM-YYYY hh:mm a"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </PageFrame>
    </>
  );
};

export default Transactions;
